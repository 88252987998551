body.dark{
  font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 15px;
  line-height: 1.42857143;
  color: #fff;
  background-color: #222;
}

body.dark a.no-styling, 
body.dark a.no-styling:hover, 
body.dark a.no-styling:focus {
  color: #fff !important;
}

body.dark .main-footer{
 /* background-color: #ecf0f5; */
}

body.dark .jumbotron{
  background-color: #222;
}

body.dark .box {
  position: relative;
  border-radius: 3px;
  background: #303030;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
body.dark .box.box-primary {
  border-top-color: #3c8dbc;
}
body.dark .box.box-info {
  border-top-color: #00c0ef;
}
body.dark  .box.box-danger {
  border-top-color: #dd4b39;
}
body.dark .box.box-warning {
  border-top-color: #f39c12;
}
body.dark  .box.box-success {
  border-top-color: #00a65a;
}
body.dark .box.box-default {
  border-top-color: #d2d6de;
}
body.dark .box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}
body.dark .box .border-right {
  border-right: 1px solid #f4f4f4;
}
body.dark .box .border-left {
  border-left: 1px solid #f4f4f4;
}
body.dark .box.box-solid.box-default {
  border: 1px solid #d2d6de;
}
body.dark .box.box-solid.box-default > .box-header {
  color: #444444;
  background: #d2d6de;
  background-color: #d2d6de;
}
body.dark .box.box-solid.box-default > .box-header a,
body.dark .box.box-solid.box-default > .box-header .btn {
  color: #444444;
}
body.dark .box.box-solid.box-primary {
  border: 1px solid #3c8dbc;
}
body.dark .box.box-solid.box-primary > .box-header {
  color: #ffffff;
  background: #3c8dbc;
  background-color: #3c8dbc;
}
body.dark .box.box-solid.box-primary > .box-header a,
body.dark .box.box-solid.box-primary > .box-header .btn {
  color: #ffffff;
}
body.dark .box.box-solid.box-info {
  border: 1px solid #00c0ef;
}
body.dark .box.box-solid.box-info > .box-header {
  color: #ffffff;
  background: #00c0ef;
  background-color: #00c0ef;
}
body.dark .box.box-solid.box-info > .box-header a,
body.dark .box.box-solid.box-info > .box-header .btn {
  color: #ffffff;
}
body.dark .box.box-solid.box-danger {
  border: 1px solid #dd4b39;
}
body.dark .box.box-solid.box-danger > .box-header {
  color: #ffffff;
  background: #dd4b39;
  background-color: #dd4b39;
}
body.dark .box.box-solid.box-danger > .box-header a,
body.dark .box.box-solid.box-danger > .box-header .btn {
  color: #ffffff;
}
body.dark .box.box-solid.box-warning {
  border: 1px solid #f39c12;
}
body.dark .box.box-solid.box-warning > .box-header {
  color: #ffffff;
  background: #f39c12;
  background-color: #f39c12;
}
body.dark .box.box-solid.box-warning > .box-header a,
body.dark .box.box-solid.box-warning > .box-header .btn {
  color: #ffffff;
}
body.dark .box.box-solid.box-success {
  border: 1px solid #00a65a;
}
body.dark .box.box-solid.box-success > .box-header {
  color: #ffffff;
  background: #00a65a;
  background-color: #00a65a;
}
body.dark .box.box-solid.box-success > .box-header a,
body.dark .box.box-solid.box-success > .box-header .btn {
  color: #ffffff;
}
body.dark .box.box-solid[class*='bg'] > .box-header {
  color: #fff;
}
body.dark .box.box-solid > .box-header .btn:hover,
body.dark .box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
body.dark .box .knob-label {
  text-align: center;
  color: #fff;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}
body.dark .box-header {
  color: #fff;
  display: block;
  padding: 10px;
  position: relative;
}
body.dark .box-header > .box-tools .dropdown-menu > li > a {
  color: #444!important;
}
body.dark .box-header.with-border {
  border-bottom: 1px solid #3c3c3c;
}

body.dark .btn:hover,
body.dark .btn:focus {
    color: #ffffff;
    text-decoration: none;
  }
body.dark .btn-default {
  color: #ffffff;
  background-color: #464545;
  border-color: #464545;
}
body.dark .btn-default:hover,
body.dark .btn-default:focus,
body.dark .btn-default:active,
body.dark .btn-default.active,
body.dark .open .dropdown-toggle.btn-default {

  color: #00c0ef;/*#ffffff;*/
  background-color: #313131;
  border-color: #272727;
}

body.dark .btn-default.disabled,
body.dark .btn-default[disabled],
body.dark fieldset[disabled] .btn-default,
body.dark .btn-default.disabled:hover,
body.dark .btn-default[disabled]:hover,
body.dark fieldset[disabled] .btn-default:hover,
body.dark .btn-default.disabled:focus,
body.dark .btn-default[disabled]:focus,
body.dark fieldset[disabled] .btn-default:focus,
body.dark .btn-default.disabled:active,
body.dark .btn-default[disabled]:active,
body.dark fieldset[disabled] .btn-default:active,
body.dark .btn-default.disabled.active,
body.dark .btn-default[disabled].active,
body.dark fieldset[disabled] .btn-default.active {
  background-color: #464545;
  border-color: #464545;
}
body.dark .btn-default .badge {
  color: #464545;
  background-color: #ffffff;
}
body.dark .btn-primary {
  color: #ffffff;
  background-color: #375a7f;
  border-color: #375a7f;
}
body.dark .btn-primary:hover,
body.dark .btn-primary:focus,
body.dark .btn-primary:active,
body.dark .btn-primary.active,
body.dark .open .dropdown-toggle.btn-primary {
  color: #ffffff;
  background-color: #2b4663;
  border-color: #253c54;
}

body.dark .btn-primary.disabled,
body.dark .btn-primary[disabled],
body.dark fieldset[disabled] .btn-primary,
body.dark .btn-primary.disabled:hover,
body.dark .btn-primary[disabled]:hover,
body.dark fieldset[disabled] .btn-primary:hover,
body.dark .btn-primary.disabled:focus,
body.dark .btn-primary[disabled]:focus,
body.dark fieldset[disabled] .btn-primary:focus,
body.dark .btn-primary.disabled:active,
body.dark .btn-primary[disabled]:active,
body.dark fieldset[disabled] .btn-primary:active,
body.dark .btn-primary.disabled.active,
body.dark .btn-primary[disabled].active,
body.dark fieldset[disabled] .btn-primary.active {
  background-color: #375a7f;
  border-color: #375a7f;
}
body.dark .btn-primary .badge {
  color: #375a7f;
  background-color: #ffffff;
}
body.dark .btn-success {
  color: #ffffff;
  background-color: #00bc8c;
  border-color: #00bc8c;
}
body.dark .btn-success:hover,
body.dark .btn-success:focus,
body.dark .btn-success:active,
body.dark .btn-success.active,
body.dark .open .dropdown-toggle.btn-success {
  color: #ffffff;
  background-color: #00936e;
  border-color: #007f5e;
}

body.dark .btn-success.disabled,
body.dark .btn-success[disabled],
body.dark fieldset[disabled] .btn-success,
body.dark .btn-success.disabled:hover,
body.dark .btn-success[disabled]:hover,
body.dark fieldset[disabled] .btn-success:hover,
body.dark .btn-success.disabled:focus,
body.dark .btn-success[disabled]:focus,
body.dark fieldset[disabled] .btn-success:focus,
body.dark .btn-success.disabled:active,
body.dark .btn-success[disabled]:active,
body.dark fieldset[disabled] .btn-success:active,
body.dark .btn-success.disabled.active,
body.dark .btn-success[disabled].active,
body.dark fieldset[disabled] .btn-success.active {
  background-color: #00bc8c;
  border-color: #00bc8c;
}
body.dark .btn-success .badge {
  color: #00bc8c;
  background-color: #ffffff;
}
body.dark .btn-info {
  color: #ffffff;
  background-color: #3498db;
  border-color: #3498db;
}
body.dark .btn-info:hover,
body.dark .btn-info:focus,
body.dark .btn-info:active,
body.dark .btn-info.active,
body.dark .open .dropdown-toggle.btn-info {
  color: #ffffff;
  background-color: #2383c4;
  border-color: #2077b2;
}

body.dark .btn-info.disabled,
body.dark .btn-info[disabled],
body.dark fieldset[disabled] .btn-info,
body.dark .btn-info.disabled:hover,
body.dark .btn-info[disabled]:hover,
body.dark fieldset[disabled] .btn-info:hover,
body.dark .btn-info.disabled:focus,
body.dark .btn-info[disabled]:focus,
body.dark fieldset[disabled] .btn-info:focus,
body.dark .btn-info.disabled:active,
body.dark .btn-info[disabled]:active,
body.dark fieldset[disabled] .btn-info:active,
body.dark .btn-info.disabled.active,
body.dark .btn-info[disabled].active,
body.dark fieldset[disabled] .btn-info.active {
  background-color: #3498db;
  border-color: #3498db;
}
body.dark .btn-info .badge {
  color: #3498db;
  background-color: #ffffff;
}
body.dark .btn-warning {
  color: #ffffff;
  background-color: #f39c12;
  border-color: #f39c12;
}
body.dark .btn-warning:hover,
body.dark .btn-warning:focus,
body.dark .btn-warning:active,
body.dark .btn-warning.active,
body.dark .open .dropdown-toggle.btn-warning {
  color: #ffffff;
  background-color: #d2850b;
  border-color: #be780a;
}

body.dark .btn-warning.disabled,
body.dark .btn-warning[disabled],
body.dark fieldset[disabled] .btn-warning,
body.dark .btn-warning.disabled:hover,
body.dark .btn-warning[disabled]:hover,
body.dark fieldset[disabled] .btn-warning:hover,
body.dark .btn-warning.disabled:focus,
body.dark .btn-warning[disabled]:focus,
body.dark fieldset[disabled] .btn-warning:focus,
body.dark .btn-warning.disabled:active,
body.dark .btn-warning[disabled]:active,
body.dark fieldset[disabled] .btn-warning:active,
body.dark .btn-warning.disabled.active,
body.dark .btn-warning[disabled].active,
body.dark fieldset[disabled] .btn-warning.active {
  background-color: #f39c12;
  border-color: #f39c12;
}
body.dark .btn-warning .badge {
  color: #f39c12;
  background-color: #ffffff;
}
body.dark .btn-danger {
  color: #ffffff;
  background-color: #e74c3c;
  border-color: #e74c3c;
}
body.dark .btn-danger:hover,
body.dark .btn-danger:focus,
body.dark .btn-danger:active,
body.dark .btn-danger.active,
body.dark .open .dropdown-toggle.btn-danger {
  color: #ffffff;
  background-color: #df2e1b;
  border-color: #cd2a19;
}

body.dark .btn-danger.disabled,
body.dark .btn-danger[disabled],
body.dark fieldset[disabled] .btn-danger,
body.dark .btn-danger.disabled:hover,
body.dark .btn-danger[disabled]:hover,
body.dark fieldset[disabled] .btn-danger:hover,
body.dark .btn-danger.disabled:focus,
body.dark .btn-danger[disabled]:focus,
body.dark fieldset[disabled] .btn-danger:focus,
body.dark .btn-danger.disabled:active,
body.dark .btn-danger[disabled]:active,
body.dark fieldset[disabled] .btn-danger:active,
body.dark .btn-danger.disabled.active,
body.dark .btn-danger[disabled].active,
body.dark fieldset[disabled] .btn-danger.active {
  background-color: #e74c3c;
  border-color: #e74c3c;
}
body.dark .btn-danger .badge {
  color: #e74c3c;
  background-color: #ffffff;
}

body.dark .table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #444;
}
body.dark .table-hover > tbody > tr:hover {
  background-color: #2d2d2d;
}

body.dark .table > thead > tr > th,
body.dark .table > tbody > tr > th,
body.dark .table > tfoot > tr > th,
body.dark .table > thead > tr > td,
body.dark .table > tbody > tr > td,
body.dark .table > tfoot > tr > td {
    border-top: 1px solid #4a4a4a;
}

body.dark hr {
    border-top: 1px solid #4a4a4a;
}

body.dark .box-footer {
    border-top: 1px solid #3c3c3c;
    background-color: #303030;
}

body.dark .table td {
    color: #b3b3b3;
}


body.dark .tabs li {
  color: #cacaca;
}
body.dark .tabs li:hover,
body.dark .tabs li.active:hover {
  border-bottom: solid 2px #3c8dbc;
  color: #fff;
}
body.dark .tabs li.active {
  color: #fff;
  border-bottom: solid 2px #797474;
}

/*END dark*/


body.light{
  font-family: "Lato","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 15px;
  line-height: 1.42857143;
  color: #484848;
  background-color: #ecf0f5;
}

body.light a.no-styling, 
body.light a.no-styling:hover, 
body.light a.no-styling:focus {
  color: #484848 !important;
}

body.light .main-footer{
  background-color: #ecf0f5;
}

body.light .box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
body.light .box.box-primary {
  border-top-color: #3c8dbc;
}
body.light .box.box-info {
  border-top-color: #00c0ef;
}
body.light  .box.box-danger {
  border-top-color: #dd4b39;
}
body.light .box.box-warning {
  border-top-color: #f39c12;
}
body.light  .box.box-success {
  border-top-color: #00a65a;
}
body.light .box.box-default {
  border-top-color: #d2d6de;
}
body.light .box .nav-stacked > li {
  border-bottom: 1px solid #f4f4f4;
  margin: 0;
}
body.light .box .border-right {
  border-right: 1px solid #f4f4f4;
}
body.light .box .border-left {
  border-left: 1px solid #f4f4f4;
}
body.light .box.box-solid.box-default {
  border: 1px solid #d2d6de;
}
body.light .box.box-solid.box-default > .box-header {
  color: #444444;
  background: #d2d6de;
  background-color: #d2d6de;
}
body.light .box.box-solid.box-default > .box-header a,
body.light .box.box-solid.box-default > .box-header .btn {
  color: #444444;
}
body.light .box.box-solid.box-primary {
  border: 1px solid #3c8dbc;
}
body.light .box.box-solid.box-primary > .box-header {
  color: #ffffff;
  background: #3c8dbc;
  background-color: #3c8dbc;
}
body.light .box.box-solid.box-primary > .box-header a,
body.light .box.box-solid.box-primary > .box-header .btn {
  color: #ffffff;
}
body.light .box.box-solid.box-info {
  border: 1px solid #00c0ef;
}
body.light .box.box-solid.box-info > .box-header {
  color: #ffffff;
  background: #00c0ef;
  background-color: #00c0ef;
}
body.light .box.box-solid.box-info > .box-header a,
body.light .box.box-solid.box-info > .box-header .btn {
  color: #ffffff;
}
body.light .box.box-solid.box-danger {
  border: 1px solid #dd4b39;
}
body.light .box.box-solid.box-danger > .box-header {
  color: #ffffff;
  background: #dd4b39;
  background-color: #dd4b39;
}
body.light .box.box-solid.box-danger > .box-header a,
body.light .box.box-solid.box-danger > .box-header .btn {
  color: #ffffff;
}
body.light .box.box-solid.box-warning {
  border: 1px solid #f39c12;
}
body.light .box.box-solid.box-warning > .box-header {
  color: #ffffff;
  background: #f39c12;
  background-color: #f39c12;
}
body.light .box.box-solid.box-warning > .box-header a,
body.light .box.box-solid.box-warning > .box-header .btn {
  color: #ffffff;
}
body.light .box.box-solid.box-success {
  border: 1px solid #00a65a;
}
body.light .box.box-solid.box-success > .box-header {
  color: #ffffff;
  background: #00a65a;
  background-color: #00a65a;
}
body.light .box.box-solid.box-success > .box-header a,
body.light .box.box-solid.box-success > .box-header .btn {
  color: #ffffff;
}
body.light .box.box-solid[class*='bg'] > .box-header {
  color: #fff;
}
body.light .box.box-solid > .box-header .btn:hover,
body.light .box.box-solid > .box-header a:hover {
  background: rgba(0, 0, 0, 0.1);
}
body.light .box-header.with-border {
  border-bottom: 1px solid #f4f4f4;
}

body.light .box .knob-label {
  text-align: center;
  color: #333;
  font-weight: 100;
  font-size: 12px;
  margin-bottom: 0.3em;
}
body.light .box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
body.light .box-header > .box-tools .dropdown-menu > li > a {
  color: #444!important;
}

body.light .jumbotron{
  background-color: #fff;
}

body.light hr {
    border-top: 1px solid #bdc9e2;
}

body.light a[class*="icon-"].white {
    color: #484848 !important;
}
body.light a[class*="icon-"].white:hover{
  color: #00c0ef !important;
}

body.light .tabs li.active {
    color: #000;
    border-bottom: solid 2px #375a7f;
}
body.light .tabs li:hover{
  color: #000;
}

/*
TODO: fix for light theme
body.light .tabs li {
  color: #cacaca;
}
body.light .tabs li:hover,
body.light .tabs li.active:hover {
 border-bottom: solid 2px #3c8dbc;
  color: #fff;
}
body.light .tabs li.active {
  color: #fff;
  border-bottom: solid 2px #797474;
}
*/


.box.collapsed-box .box-body,
.box.collapsed-box .box-footer {
  display: none;
}

.box .nav-stacked > li:last-of-type {
  border-bottom: none;
}
.box.height-control .box-body {
  max-height: 300px;
  overflow: auto;
}
.box.box-solid {
  border-top: 0;
}
.box.box-solid > .box-header .btn.btn-default {
  background: transparent;
}
.box.box-solid > .box-header > .box-tools .btn {
  border: 0;
  box-shadow: none;
}
.box .box-group > .box {
  margin-bottom: 5px;
}
.box > .overlay,
.overlay-wrapper > .overlay,
.box > .loading-img,
.overlay-wrapper > .loading-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.box .overlay,
.overlay-wrapper .overlay {
  z-index: 50;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 3px;
}
.box .overlay > .fa,
.overlay-wrapper .overlay > .fa {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -15px;
  color: #000;
  font-size: 30px;
}
.box .overlay.dark,
.overlay-wrapper .overlay.dark {
  background: rgba(0, 0, 0, 0.5);
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.box-header:after,
.box-body:after,
.box-footer:after {
  clear: both;
}


.collapsed-box .box-header.with-border {
  border-bottom: none;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion,
.box-header .box-title {
  display: inline-block;
  font-size: 18px;
  margin: 0;
  line-height: 1;
}
.box-header > .fa,
.box-header > .glyphicon,
.box-header > .ion {
  margin-right: 5px;
}
.box-header > .box-tools {
  position: absolute;
  right: 54px;
  top: 5px;
}
.box-header > .box-tools [data-toggle="tooltip"] {
  position: relative;
}
.box-header > .box-tools.pull-right .dropdown-menu {
  right: 0;
  left: auto;
}

.btn-box-tool {
  padding: 5px;
  font-size: 12px;
  background: transparent;
  color: #97a0b3;
}
.open .btn-box-tool,
.btn-box-tool:hover {
  color: #606c84;
}
.btn-box-tool.btn:active {
  box-shadow: none;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}
.no-header .box-body {
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.box-body > .table {
  margin-bottom: 0;
}
.box-body .fc {
  margin-top: 5px;
}
.box-body .full-width-chart {
  margin: -19px;
}
.box-body.no-padding .full-width-chart {
  margin: -9px;
}
.box-body .box-pane {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 3px;
}
.box-body .box-pane-right {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 0;
}
.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #ffffff;
  margin-bottom: 45px;
}

.navbar-default{
  background-color: #375a7f;
  border-color: transparent;
  margin-bottom:0;
  z-index: 1000;
}
.navbar-default .navbar-brand {
  color: #ffffff;
}
.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #00c0ef;
  background-color: transparent;
}
.navbar-default .navbar-text {
  color: #777777;
}
.navbar-default .navbar-nav > li > a {
  color: #ffffff;
}
.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
  color: #00c0ef;
  background-color: transparent;
}
.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #ffffff;
  background-color: #28415b;
}
.navbar-default .navbar-nav > .disabled > a,
.navbar-default .navbar-nav > .disabled > a:hover,
.navbar-default .navbar-nav > .disabled > a:focus {
  color: #cccccc;
  background-color: transparent;
}
.navbar-default .navbar-toggle {
  border-color: #28415b;
}
.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
  background-color: #28415b;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #ffffff;
}
.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: transparent;
}
.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
  background-color: #28415b;
  color: #ffffff;
}
@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #ffffff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #00c0ef;;
    background-color: transparent;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #ffffff;
    background-color: #28415b;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #cccccc;
    background-color: transparent;
  }
}
.navbar-default .navbar-link {
  color: #ffffff;
}
.navbar-default .navbar-link:hover {
  color: #00c0ef;;
}


@media print {
  h2.icon-calculator.short,
  .box-footer { display: none; }

  body.dark .box.box-primary,
  body.light .box.box-primary {
    border-top-color:transparent !important;
  }
}